<template lang="pug">
om-modal.brand-font-management(
  name="font-management"
  color="light"
  width="100%"
  :addOverflowScroll="false"
  @beforeOpen="beforeOpen"
)
  template(slot="modal-header")
    .d-flex
      .brand-modal-title.flex-grow-0.mr-5 {{ $t('fontManager.title') }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide('font-management')" title="close" aria-hidden="true") X
  template(slot="modal-body")
    AddModal(@save="onSave")
    .brand-integrations-holder
      expandable(
        v-if="!search.type"
        :title="`${$t('fontManager.customTitle')} (${customCount})`"
        :infiniteLoad="false"
      )
        template(slot="before-content")
          .m-2
            .brand-btn.brand-btn-primary.brand-btn-sm.brand-btn-inline(
              @click="$modal.show('custom-font-upload')"
            ) {{ $t('uploadNew') }}
        template(slot="content")
          custom-font-box(
            v-for="font in getFonts('custom')"
            :font="font"
            :key="`font-${font.key}`"
          )
      expandable(
        v-if="!search.type"
        :title="`${$t('fontManager.preInstalledTitle')} (${systemCount})`"
        :infiniteLoad="true"
        @loadMore="loadMore($event, 'system')"
      )
        template(slot="before-content")
          Search(
            :items="getSearchItems('system')"
            @search="searchBy($event, 'system')"
            @clear="clearSearch('system')"
          )
        template(slot="content")
          GFontsBox(
            v-for="font in getFonts('system')"
            :font="font"
            :key="`font-${font.key}`"
            @add="onAddClick($event, true)"
          )

      expandable.brand-expandable(
        v-if="installed.length && !search.type"
        :infiniteLoad="true"
        :title="`${$t('fontManager.installedTitle')} (${installedCount})`"
        @loadMore="loadMore($event, 'installed')"
      )
        template(slot="before-content")
          Search(
            :items="getSearchItems('installed')"
            @search="searchBy($event, 'installed')"
            @clear="clearSearch('installed')"
          )
        template(slot="content")
          GFontsBox(
            v-for="font in getFonts('installed')"
            :font="font"
            :key="`font-${font.key}`"
            @add="onAddClick($event, true)"
            @remove="onRemoveClick($event)"
          )

      expandable.brand-expandable(
        v-if="!search.type"
        :showContent="true"
        :infiniteLoad="true"
        :title="`${$t('fontManager.availableTitle')} (${available.length})`"
        @loadMore="loadMore($event, 'available')"
      )
        template(slot="before-content")
          Search(
            :items="getSearchItems('available')"
            @search="searchBy($event, 'available')"
            @clear="clearSearch('available')"
          )
        template(slot="content")
          GFontsBox(
            v-for="font in getFonts('available')"
            :infiniteLoad="true"
            :font="font"
            :key="`font-${font.key}`"
            @add="onAddClick($event)"
          )

      .font-manager-search-results.p-3(v-if="search.type")
        div(style="position: relative; z-index: 2")
          Search(
            :items="getSearchItems(search.type)"
            :initSelected="search.selected"
            :initSubsets="search.subsets"
            @search="searchBy($event, search.type)"
            @clear="clearSearch(search.type)"
          )
        .row.m-0(style="position: relative; z-index: 1")
          GFontsBox(
            v-for="font in search[search.type].slice(0, search[`${search.type}Loaded`])"
            :font="font"
            :key="`font-${font.key}`"
            @add="onAddClick($event)"
          )
          InfiniteLoad(
            @infinite="loadMoreResult($event)"
            :id="search.type"
            wrapper=".brand-integrations-holder"
          )
  template.p-0(slot="modal-footer")
    //- .d-flex.justify-content-end
    //-   .brand-btn.brand-btn-secondary(@click="$modal.hide('font-management')") {{ $t('close') }}
</template>

<script>
  import { get as _get } from 'lodash-es';
  import { mapState, mapMutations } from 'vuex';
  import fontCounter from '@/mixins/fontCounter';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import Search from './fontManager/Search.vue';
  import GFontsBox from './fontManager/GFontsBox.vue';
  import CustomFontBox from './fontManager/CustomFontBox.vue';
  import AddModal from './fontManager/AddModal.vue';
  import InfiniteLoad from '../../../components/OmInfiniteLoading.vue';

  export default {
    components: { GFontsBox, CustomFontBox, AddModal, Search, InfiniteLoad },
    mixins: [fontCounter],
    data: () => ({
      systemLoaded: 10,
      installedLoaded: 10,
      availableLoaded: 10,
      search: {
        type: null,
        selected: [],
        subsets: [],
        system: null,
        systemLoaded: 10,
        installed: null,
        installedLoaded: 10,
        available: null,
        availableLoaded: 10,
      },
    }),
    computed: {
      ...mapState({
        fonts(state) {
          if (!state.fonts) return [];
          const fonts = [];
          Object.keys(state.fonts).forEach((key) => {
            fonts.push({
              key,
              ...state.fonts[key],
            });
          });
          return fonts;
        },
      }),
      custom() {
        return this.fonts.filter((font) => font.custom);
      },
      system() {
        return this.fonts.filter((font) => font.preInstalled);
      },
      installed() {
        return this.fonts.filter((font) => !font.preInstalled && !!font.installedSubsets);
      },
      available() {
        return this.fonts.filter((font) => !font.preInstalled && !font.installedSubsets);
      },
    },
    watch: {
      installed() {
        this.refreshSearch();
      },
      available() {
        this.refreshSearch();
      },
    },
    methods: {
      ...mapMutations(['updateFont']),
      getFonts(type) {
        return this[type].slice(0, this[`${type}Loaded`]);
      },
      getSearchItems(type) {
        return this[type];
      },
      loadMore($state, path) {
        const isSearch = path.indexOf('search') !== -1;
        const loadedPath = `${path}Loaded`;
        const currentlyLoaded = _get(this, loadedPath);
        const isCompleted = currentlyLoaded >= _get(this, path, []).length;
        if (isCompleted) return $state.complete();
        if (!isSearch) this.$set(this, loadedPath, currentlyLoaded + 10);
        else this.$set(this.search, loadedPath.replace('search.', ''), currentlyLoaded + 10);
        $state.loaded();
      },
      loadMoreResult($state) {
        this.loadMore($state, `search.${this.search.type}`);
      },
      getWeights(font) {
        return font.variants.map((variant) => {
          const rawWeight = /(\d+)/g.exec(variant) ? /(\d+)/g.exec(variant)[1] : '400';
          const weight = parseInt(rawWeight, 10);
          const italic = variant.indexOf('i') !== -1;
          return `${weight}${italic ? 'i' : ''}`;
        });
      },
      getFont(key) {
        const font = this.fonts.find((font) => font.key === key);
        return font;
      },
      onAddClick({ key, variants }, isInstalled) {
        const font = this.getFont(key);
        font.availableVariants = variants;
        this.$nextTick(() => {
          this.$modal.show('font-settings', {
            transKey: isInstalled ? 'save' : 'add',
            font,
            weights: this.getWeights(font),
          });
        });
      },
      onRemoveClick(key) {
        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this.updateFont({ key, subsets: null });
                this.$modal.hide('dialog');
              },
            },
            {
              title: this.$t('cancel'),
              default: true,
              class: getBrandedClassString({ secondary: true }),
            },
          ],
        });
      },
      onSave(payload) {
        payload.weights = this.getWeights(this.getFont(payload.key));
        this.updateFont(payload);
      },
      searchBy({ selected, subsets }, type) {
        this.search.type = type;
        this.search.selected = selected;
        this.search.subsets = subsets;
        let toFilter = this[type];
        if (selected && selected.length) {
          this.search[type] = toFilter.filter((font) => selected.includes(font.key));
          toFilter = this.search[type];
        }
        if (subsets && subsets.length)
          this.search[type] = toFilter.filter((font) => {
            let result = false;
            font.subsets.forEach((subset) => {
              if (subsets.includes(subset)) result = true;
            });
            return result;
          });
      },
      clearSearch(type) {
        this.search.type = null;
        this.search[type] = null;
        this.search[`${type}Loaded`] = 10;
      },
      refreshSearch() {
        if (this.search[this.search.type] && this.search[this.search.type].length) {
          this.searchBy(this.search, this.search.type);
        } else {
          this.clearSearch(this.search.type);
        }
      },
      beforeOpen() {
        if (this.search.type) {
          this.clearSearch(this.search.type);
        }
      },
    },
  };
</script>
<style lang="sass">
  .brand-font-management.v--modal-overlay:not(.centered-modal)
    .v--modal-background-click
      display: flex
      justify-content: center
      align-content: center
    .v--modal
      top: auto !important
</style>
